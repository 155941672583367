// imports template

/* eslint-disable @typescript-eslint/no-unused-vars */

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { orange } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem";
import NoSsr from "@material-ui/core/NoSsr";
import Paper from "@material-ui/core/Paper";
import styled from "@material-ui/core/styles/styled";
import {
  createMuiTheme,
  createStyles,
  emphasize,
  makeStyles,
  useTheme,
  Theme,
  Theme as AugmentedTheme,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import { ThemeProvider } from "@material-ui/styles";
import { Omit } from "@material-ui/types";
import React, { CSSProperties, HTMLAttributes, useEffect } from "react";

// -----------------------------------------------------------------------------

import "./App.css";
import logo from "./logo.svg";

import * as a0MyApp from "./a0-my-app";
import * as a0ReactHooks from "./a0-react-hooks";
import * as appMd2TracSrcApp from "./app-md2trac-src/App";
import * as appTracsyncApp from "./app-tracsync-src/App";
import * as appWataashApp from "./app-wataash-src/App";
import * as muiComponentsAutocomplete from "./mui-components-autocomplete";
import * as muiCustomizationTheming from "./mui-customization-theming";
import * as muiStylesAdvanced from "./mui-styles-advanced";
import * as muiStylesBasics from "./mui-styles-basics";
import * as select from "./select";

import * as jsx from "./jsx";
const tutorial = require("./tutorial");

tutorial.main();

export function App2() {
  return <appWataashApp.default />
  return (
    <div>
      <Sandbox />

      {/* 以下をuncomment outして、ブラウザで見て、デバッグすれば復習できる */}

      {/*<a00Scraps attr0={"ATTR0"}/>*/}
      {/*<a0MyApp.Game />*/}
      {/*<a0ReactHooks.Example />*/}

      {/*<appMd2TracSrcApp.default />*/}
      {/*<appTracsyncApp.default />*/}
      {/*<appWataashApp.default />*/}

      {/* TODO: 以下old */}

      {/*<muiStylesBasics.Hook />*/}
      {/*<muiStylesBasics.MyButton>foo</muiStylesBasics.MyButton>*/}

      {/*<muiComponentsAutocomplete.default />*/}

      {/*<muiCustomizationTheming.default />*/}
      {/*<div style={{ verticalAlign: 'middle' }}></div>*/}

      {/*<muiStylesAdvanced.Theming />*/}
      {/*<muiStylesAdvanced.UseTheme />*/}

      {/*<select.SelectApp />*/}
    </div>
  );
};

// {Sandbox()} でなく <Sandbox /> と書く場合は最初が大文字じゃないといけないらしい; 最初が小文字はHTML予約だとか
// OK
// {sandbox()}
// {Sandbox()}
// <Sandbox />
// Property 'sandbox' does not exist on type 'JSX.IntrinsicElements'.  TS2339
// <sandbox />
function Sandbox() {
  return <div>
    <p>sandbox</p>
  </div>;
}

function a00Scraps(prop: {attr0?: string, attr1?: string}) {
  return <div>
    {/* attributes */}
    <p>scraps: attr0: {prop.attr0 === undefined ? "undefined" : prop.attr0}</p>
    <p>scraps: attr1: {prop.attr1 === undefined ? "undefined" : prop.attr1}</p>

    {/* jsx.jsx */}
    {jsx.tes()}
    {<jsx.tes />}

    {/* one-line if */}
    {/* {true ? <p>true</p> : <p>false</p>} */}

    {/* raw HTML */}
    <div
      dangerouslySetInnerHTML={{
        __html: "dangerouslySetInnerHTML<br />"
      }}
    />

    {/* raw js */}
    {(() => {
      console.log("raw js");
    })()}
  </div>;
}
