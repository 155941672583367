import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-69723762-6');
ReactGA.pageview(window.location.pathname + window.location.search);

const App: React.FC = () => {
  return (
    <div className="App">
      {/*<header className="App-header">*/}
      {/*  <img src={logo} className="App-logo" alt="logo" />*/}
      {/*  <p>*/}
      {/*    Edit <code>src/App.tsx</code> and save to reload.*/}
      {/*  </p>*/}
      {/*  <a*/}
      {/*    className="App-link"*/}
      {/*    href="https://reactjs.org"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    Learn React*/}
      {/*  </a>*/}
      {/*</header>*/}

      <Box m={2}>
        {/**/}

        {/*<Typography><Link href="https://blog.wataash.com">Blog</Link></Typography>*/}
        {/*<Typography><Link href="https://jblog.wataash.com">Blog (Japanese)</Link></Typography>*/}

        {/*<MenuList>*/}
        {/*  <a href="http://example.com"><MenuItem >Profile</MenuItem></a>*/}
        {/*  <MenuItem >My account</MenuItem>*/}
        {/*  <MenuItem>Logout</MenuItem>*/}
        {/*  <MenuItem component={Link} to="/">*/}
        {/*    Home*/}
        {/*  </MenuItem>*/}
        {/*</MenuList>*/}

        {/*<List component="nav" aria-label="main mailbox folders">*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemIcon><InboxIcon /></ListItemIcon>*/}
        {/*    <ListItemText primary="Inbox" />*/}
        {/*  </ListItem>*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemIcon><DraftsIcon /></ListItemIcon>*/}
        {/*    <ListItemText primary="Drafts" />*/}
        {/*  </ListItem>*/}
        {/*</List>*/}
        {/*<Divider />*/}
        {/*<List component="nav" aria-label="secondary mailbox folders">*/}
        {/*  <ListItem button><ListItemText primary="Trash" /></ListItem>*/}
        {/*  <ListItemLink href="#simple-list"><ListItemText primary="Spam" /></ListItemLink>*/}
        {/*</List>*/}

        <Box m={3}><Typography component="h1" variant="h4">wataash.com</Typography></Box>

        <List>
          <ListItemLink href="https://blog.wataash.com"><ListItemText primary="Blog" /></ListItemLink>
        </List>

        <Divider />

        <List>
          <ListItemLink href="https://dev.to/wataash"><ListItemText primary="dev.to" /></ListItemLink>
          <ListItemLink href="https://www.facebook.com/wataash"><ListItemText primary="FaceBook" /></ListItemLink>
          <ListItemLink href="https://github.com/wataash"><ListItemText primary="GitHub" /></ListItemLink>
          <ListItemLink href="https://gitlab.com/wataash"><ListItemText primary="GitLab" /></ListItemLink>
          <ListItemLink href="https://www.instagram.com/wataruash/"><ListItemText primary="Instagram" /></ListItemLink>
          <ListItemLink href="https://www.linkedin.com/in/wataru-ashihara-40326aa3/"><ListItemText primary="LinkedIn" /></ListItemLink>
          <ListItemLink href="https://medium.com/@wataash"><ListItemText primary="Medium" /></ListItemLink>
          <ListItemLink href="https://qiita.com/wataash"><ListItemText primary="Qiita" /></ListItemLink>
          <ListItemLink href="https://stackoverflow.com/users/4085441/wataash"><ListItemText primary="Stack Overflow" /></ListItemLink>
          <ListItemLink href="https://twitter.com/wata_ash"><ListItemText primary="Twitter" /></ListItemLink>
        </List>

        {/*  */}
      </Box>
    </div>
  );
};

export default App;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
    }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}
