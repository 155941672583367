/*
[react-tutorial]
https://ja.reactjs.org/tutorial/tutorial.html
  最終的な結果は、ここで確認することができます：最終結果 https://codepen.io/gaearon/pen/gWWZgR

クリック: <body class="mouse-navigation">
TAB:      <body class="kbd-navigation">

console.error() 改造
*/

export function main() {
  window.addEventListener("mousedown", function(e) {
    document.body.classList.add("mouse-navigation");
    document.body.classList.remove("kbd-navigation");
  });
  window.addEventListener("keydown", function(e) {
    if (e.keyCode === 9) {
      // tab
      document.body.classList.add("kbd-navigation");
      document.body.classList.remove("mouse-navigation");
    }
  });
  window.addEventListener("click", function(e) {
    if (e.target.tagName === "A" && e.target.getAttribute("href") === "#") {
      // ?
      e.preventDefault();
    }
  });
  window.onerror = function(message, source, line, col, error) {
    var text = error
      ? error.stack || error
      : message + " (at " + source + ":" + line + ":" + col + ")";
    // eslint-disable-next-line no-undef
    errors42.textContent += text + "\n";
    // eslint-disable-next-line no-undef
    errors42.style.display = "";
  };
  console.error = (function(old) {
    return function error() {
      // eslint-disable-next-line no-undef
      errors42.textContent +=
        Array.prototype.slice.call(arguments).join(" ") + "\n";
      // eslint-disable-next-line no-undef
      errors42.style.display = "";
      old.apply(this, arguments);
    };
  })(console.error);
}
